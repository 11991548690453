/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import ReactModal from 'react-modal';
import reviewsStyles from './reviews.module.scss';
import ReviewsModal from '../reviewsModal/reviewsModal';
import HorizontalList from '../horizontalList/horizontalList';
import ReviewStars from '../reviewStars/reviewStars';

const Reviews = ({ reviews, cityName, lawnPhotos }) => {
    ReactModal.setAppElement('#___gatsby');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [innerWidth, setInnerWidth] = useState(0);
    const averageRating = _.meanBy(reviews, (review) => {
        return review.rating;
    });
    const reviewSize = _.size(reviews);
    const firstTwelveReviews = reviews.slice(0, 12);
    const {
        header, card, service, excerpt, noImage, date,
    } = reviewsStyles;
    // need the weird long version of _.orderBy here since gatsby blows up with shorthands w/real builds
    const sortedLawnPhotos = _.orderBy(
        lawnPhotos,
        [
            (photo) => {
                return photo.rating;
            },
            (photo) => {
                return photo.createdAt;
            },
        ],
        ['desc', 'desc'],
    );

    const setInnerWidthValue = () => {
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        setInnerWidthValue();
        window.addEventListener('resize', setInnerWidthValue);

        return () => {
            window.removeEventListener('resize', setInnerWidthValue);
        };
    }, []);

    const showPhoto = (idx) => {
        // if we have at least as many photos as we do reviews, always show photo
        if (_.size(sortedLawnPhotos) >= _.size(firstTwelveReviews)) {
            return true;
        }

        const reviewPosition = (idx + 1) % 3;
        // position 1 is left, 2 is middle and 0 is right for 3 up view
        // if we are less than 1080 though, we only have 1
        // review visible so always show image if we have one.
        if (_.get(sortedLawnPhotos, `${[idx]}`)) {
            if (
                innerWidth < 1080
                || (reviewPosition === 1 && _.get(sortedLawnPhotos, `${[idx + 2]}`))
                || (reviewPosition === 2 && _.get(sortedLawnPhotos, `${[idx + 1]}`))
                || reviewPosition === 0
            ) {
                return true;
            }
        }

        return false;
    };

    const componentArray = _.map(firstTwelveReviews, (review, idx) => {
        // need to check for a state for the Washington, DC case since it doesn't have one
        const stateName = _.get(review.city, 'state.name');
        const showLawnPhoto = showPhoto(idx);

        return (
            <div className={card} key={review.id}>
                <div>
                    {showLawnPhoto ? (
                        <div
                            data-testid="image"
                            role="img"
                            aria-label="Lawn Photo"
                            // keeping all css inline as it's related to the bg image
                            style={{
                                backgroundImage: `url(${sortedLawnPhotos[idx].photo.localFile.childImageSharp.fixed.src})`,
                                width: '100%',
                                height: '180px',
                                marginBottom: '15px',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                            }}
                        />
                    ) : null}
                    <div className={service} data-testid="stars">
                        <ReviewStars rating={review.rating} />
                    </div>
                    <div className={service}>
                        {review.serviceName} by {review.providerName}
                    </div>
                    <div className={service}>{review.companyName}</div>
                    <div className={excerpt}>
                        <p className={showLawnPhoto ? '' : noImage}>{review.text}</p>
                    </div>
                </div>
                <div>
                    <p className={date} data-testid="location">
                        {_.toUpper(review.city.name)}
                        {stateName ? `, ${_.toUpper(review.city.state.name)}` : ''} |{' '}
                        {_.toUpper(dayjs(review.date).format('MMM DD, YYYY'))}
                    </p>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className={header}>
                <h2>Reviews</h2>
                <span data-testid="averageRating">
                    {/* always use <a/> tags if linking to same page, not <Link/> */}
                    Overall Rating: {averageRating.toFixed(2)} / 5 stars (
                    <a
                        href=""
                        data-testid="reviewsLink"
                        onClick={(e) => {
                            e.preventDefault();
                            return setIsModalOpen(true);
                        }}
                    >
                        {reviewSize === 1 ? '1 review' : `${reviewSize} reviews`}
                    </a>
                    )
                </span>
            </div>
            <div>
                <HorizontalList
                    componentArray={componentArray}
                    innerWidth={innerWidth}
                    onlyThreeOrOne
                />
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    return setIsModalOpen(false);
                }}
                className="reviewsModal"
                overlayClassName="modalOverlay"
            >
                <ReviewsModal
                    reviews={reviews}
                    cityName={cityName}
                    onCloseModal={() => {
                        return setIsModalOpen(false);
                    }}
                />
            </ReactModal>
        </>
    );
};

Reviews.propTypes = {
    cityName: PropTypes.string.isRequired,
    lawnPhotos: PropTypes.arrayOf(
        PropTypes.shape({
            photo: PropTypes.shape({
                localFile: PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                        fixed: PropTypes.shape({
                            src: PropTypes.string,
                        }),
                    }),
                }),
            }),
            rating: PropTypes.number,
            createdAt: PropTypes.string,
        }),
    ).isRequired,
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            rating: PropTypes.number,
            text: PropTypes.string,
            date: PropTypes.string,
            companyName: PropTypes.string,
            providerName: PropTypes.string,
            serviceName: PropTypes.string,
            city: PropTypes.shape({
                name: PropTypes.string,
                state: PropTypes.shape({
                    name: PropTypes.string,
                }),
            }),
        }).isRequired,
    ).isRequired,
};

export default Reviews;
