import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import PopularLocationsStyles from './popularLocations.module.scss';
import { generateServiceCityLinkData } from '../../helpers';

const PopularLocations = () => {
    const data = useStaticQuery(graphql`
    query PopularLocationsQuery {
        graphCMS {
            featuredLocationLists(last: 1) {
                id
                service {
                    id
                    slug
                }
                cities (orderBy: name_ASC) {
                    id
                    slug
                    name
                    state {
                        slug
                    }
                }
            }
        }
    }
`);

    const { container, heading } = PopularLocationsStyles;
    const { featuredLocationLists } = data.graphCMS;
    const { cities, service } = _.head(featuredLocationLists);


    return (
        <div className={container}>
            <h2 className={heading}>Popular Locations</h2>
            <ul>
                {
                    _.map(cities, (city) => {
                        const { to, linkText } = generateServiceCityLinkData({ city, service });

                        return (
                            <li key={to}>
                                <Link
                                    to={to}
                                    data-testid={to}
                                >
                                    {`${linkText} lawn care`}
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};


export default PopularLocations;
