/* eslint-disable react/no-danger */
import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import _ from 'lodash';
import resourceCardStyles from './resourceCard.module.scss';

const ResourceCard = ({ node }) => {
    const {
        card, link, title, excerpt, date,
    } = resourceCardStyles;

    const fluid = _.get(node, 'featured_media.localFile.childImageSharp.fluid');
    const backupImage = useStaticQuery(graphql`
        query {
            localFile: file(relativePath: { eq: "lawn-background-full.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 300, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <div
            data-testid="resource-card"
            className={link}
            tabIndex="0"
            role="link"
            onMouseDown={(e) => {
                // old school way of preventing blue accessibility ring
                // when clicking - has to be onMouseDown, not onClick
                e.currentTarget.style.outline = 'none';
            }}
            onClick={() => {
                navigate(node.path);
            }}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    navigate(node.path);
                }
            }}
        >
            <div className={card}>
                <Img fadeIn={false} fluid={fluid || backupImage.localFile.childImageSharp.fluid} data-testid="image" />
                <div data-testid="title" className={title} dangerouslySetInnerHTML={{ __html: node.title }} />
                {excerpt ? (
                    <div className={excerpt} dangerouslySetInnerHTML={{ __html: node.excerpt }} data-testid="excerpt" />
                ) : null}
                <div>
                    <span className={date}>{_.toUpper(node.date)}</span>
                </div>
            </div>
        </div>
    );
};

ResourceCard.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        path: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string,
        featured_media: PropTypes.shape({
            localFile: PropTypes.object,
        }),
    }).isRequired,
};

export default ResourceCard;
