import React from 'react';
import PropTypes from 'prop-types';
import CtaBannerStyles from './ctaBanner.module.scss';
import JumpLinkButton from '../jumpLinkButton/jumpLinkButton';

const CtaBanner = ({ heading, subHeading }) => {
    const { ctaBannerContainer } = CtaBannerStyles;

    return (
        <div className={ctaBannerContainer} data-testid="cta-banner">
            <div>
                <h2>{heading}</h2>
                <h4>{subHeading}</h4>
            </div>
            <div>
                <JumpLinkButton text="Get Free Quotes" />
            </div>
        </div>
    );
};

CtaBanner.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
};

export default CtaBanner;
