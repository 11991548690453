import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import Img from 'gatsby-image';
import richContentSectionsStyles from './richContentSections.module.scss';

const RichContentSections = ({ sections }) => {
    const { imageWrapper, caption } = richContentSectionsStyles;

    return (
        <>
            {_.map(sections, ({
                body, heading, navText, image,
            }, idx) => {
                return (
                    <div key={idx} data-testid="sections">
                        {
                            navText
                                ? (
                                    <h2 id={_.kebabCase(navText)} data-testid="nav-text">
                                        {heading}
                                    </h2>
                                )
                                : (
                                    <h2>
                                        {heading}
                                    </h2>
                                )
                        }
                        {
                            image ? (
                                <div className={imageWrapper} data-testid="image">
                                    <Img fadeIn={false} fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
                                    <span className={caption} data-testid="caption">{image.caption || ''}</span>
                                </div>
                            ) : null
                        }
                        <ReactMarkdown source={body} escapeHtml={false} />
                    </div>
                );
            })}
        </>
    );
};

RichContentSections.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        navText: PropTypes.string,
        body: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
            caption: PropTypes.string,
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fluid: PropTypes.object,
                }),
            }),
        }),
    })).isRequired,
};

export default RichContentSections;
