import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import AreaSVG from '../../assets/service-project-area.svg';
import PriceSVG from '../../assets/service-project-price.svg';
import DetailSVG from '../../assets/service-project-detail.svg';

import serviceProjectsStyles from './serviceProjects.module.scss';

const ServiceProjectItem = ({ serviceItem, cityName, stateSlug }) => {
    const {
        serviceName, jobDetails, price, yardSize, requesterName,
    } = serviceItem;

    const {
        serviceProjectItemContainer,
        serviceProjectItem,
        serviceProjectHeading,
        serviceProjectContent,
        serviceProjectContentItem,
        serviceProjectDetails,
        serviceProjectLeft,
        serviceProjectRight,
        serviceProjectSubHeading,
        serviceProjectJobDetails,
        serviceProjectViewMore,
        serviceProjectViewLess,
        serviceProjectViewMoreWrapper,
        serviceProjectItemViewMoreLink,
    } = serviceProjectsStyles;

    const [isViewMore, setIsViewMore] = useState(false);
    const [hasTextOverflow, setHasTextOverflow] = useState(false);
    const jobDetailsRef = React.createRef();
    const priceInDollars = price / 100;
    const formattedPrice = `$${Intl.NumberFormat().format(priceInDollars)}`;
    const formattedArea = `${Intl.NumberFormat().format(yardSize)} square feet`;

    useEffect(() => {
        const jobDetailsNode = jobDetailsRef.current;
        const isOverflow = jobDetailsNode.scrollHeight > jobDetailsNode.clientHeight
            || jobDetailsNode.scrollWidth > jobDetailsNode.clientWidth;
        setHasTextOverflow(isOverflow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Only re-sub

    function toggleViewMore(e) {
        e.preventDefault();
        setIsViewMore(!isViewMore);
    }

    let subHeadingText = `${cityName}, ${stateSlug}`;
    subHeadingText = requesterName
        ? `${subHeadingText} | REQUESTED BY ${requesterName}`
        : subHeadingText;

    return (
        <div data-testid="service-project-item" className={serviceProjectItemContainer}>
            <div className={serviceProjectItem}>
                <h4 className={serviceProjectHeading}>{serviceName}</h4>
                <div className={serviceProjectSubHeading}>{subHeadingText}</div>
                <div className={serviceProjectContent}>
                    <div className={serviceProjectContentItem}>
                        <AreaSVG />
                        {formattedArea}
                    </div>
                    <div className={serviceProjectContentItem}>
                        <PriceSVG />
                        {formattedPrice}
                    </div>
                    <div className={serviceProjectDetails}>
                        <DetailSVG className={serviceProjectLeft} />
                        <div
                            ref={jobDetailsRef}
                            className={`${serviceProjectRight} ${
                                isViewMore ? serviceProjectViewMore : serviceProjectViewLess
                            }`}
                        >
                            <ReactMarkdown
                                className={serviceProjectJobDetails}
                                source={jobDetails}
                                escapeHtml={false}
                            />
                            {hasTextOverflow ? (
                                <div className={serviceProjectViewMoreWrapper} />
                            ) : null}
                        </div>
                    </div>
                </div>
                {hasTextOverflow ? (
                    <a
                        className={serviceProjectItemViewMoreLink}
                        data-testid="toggle-view-more"
                        href="/"
                        onClick={toggleViewMore}
                    >
                        {isViewMore ? ' View Less' : 'View More'}
                    </a>
                ) : null}
            </div>
        </div>
    );
};

ServiceProjectItem.propTypes = {
    cityName: PropTypes.string.isRequired,
    stateSlug: PropTypes.string.isRequired,
    serviceItem: PropTypes.shape({
        serviceName: PropTypes.string,
        jobDetails: PropTypes.string,
        price: PropTypes.number,
        yardSize: PropTypes.number,
        zip: PropTypes.string,
        requesterName: PropTypes.string,
        requestedDate: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
    }).isRequired,
};

export default ServiceProjectItem;
