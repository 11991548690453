/* eslint-disable no-undef */
import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import horizontalListStyles from './horizontalList.module.scss';
import Chevron from '../../assets/chevron-large.svg';
import '@brainhubeu/react-carousel/lib/style.css';

// eslint-disable-next-line arrow-body-style
const LsCarousel = loadable(() => import('../carousel/carousel'));

const HorizontalList = ({ componentArray, onlyThreeOrOne }) => {
    const {
        chevron, chevronLeft, chevronRight, components,
    } = horizontalListStyles;

    return (
        <div>
            <LsCarousel
                data-testid="carousel"
                draggable={false}
                clickToChange={false}
                className={components}
                arrowLeft={
                    <Chevron
                        data-testid="chevron-left"
                        className={`${chevron} ${chevronLeft}`}
                    />
                }
                arrowRight={
                    <Chevron
                        data-testid="chevron-right"
                        className={`${chevron} ${chevronRight}`}
                    />
                }
                addArrowClickHandler
                slidesPerPage={3}
                slidesPerScroll={3}
                slides={componentArray}
                breakpoints={{
                    1080: {
                        slidesPerPage: onlyThreeOrOne ? 1 : 2,
                        slidesPerScroll: onlyThreeOrOne ? 1 : 2,
                    },
                    800: {
                        slidesPerPage: 1,
                        slidesPerScroll: 1,
                    },
                }}

            />
        </div>
    );
};

HorizontalList.propTypes = {
    componentArray: PropTypes.arrayOf(PropTypes.node).isRequired,
    onlyThreeOrOne: PropTypes.bool,
};

HorizontalList.defaultProps = {
    onlyThreeOrOne: false,
};

export default HorizontalList;
