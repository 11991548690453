import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dynamicTableStyles from './dynamicTable.module.scss';

const DynamicTable = ({ tableRows }) => {
    const {
        container, table, headerCell, row, cell, cellFirst,
    } = dynamicTableStyles;

    return (
        <div
            data-testid="container"
            className={container}
        >
            <div className={table} data-testid="table">
                <div className={row}>
                    {_.map(tableRows[0], (header, idx) => {
                        return (
                            <div
                                key={`${header}${idx}`}
                                data-testid="header"
                                // eslint-disable-next-line eqeqeq
                                className={`${headerCell} ${cell} ${idx == 0 ? cellFirst : ''}`}
                            >
                                {header}
                            </div>

                        );
                    })}
                </div>

                {_.map(tableRows, (infoRow, idx) => {
                    if (idx) {
                        return (
                            <div className={row} key={`${infoRow}${idx}`}>
                                {
                                    _.map(infoRow, (infoCell, index) => {
                                        return (
                                            <div
                                                data-testid="cell"
                                                // eslint-disable-next-line eqeqeq
                                                className={`${cell} ${index == 0 ? cellFirst : ''}`}
                                                key={`${infoCell}${index}`}
                                            >
                                                {infoCell}
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        );
                    }
                    return null;
                })}

            </div>
        </div>
    );
};

DynamicTable.propTypes = {
    tableRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})])).isRequired,
};

export default DynamicTable;
