import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import factListStyles from './factList.module.scss';


const FactList = ({ factList }) => {
    const {
        list, itemContainer, item,
    } = factListStyles;
    const {
        navText, description, heading, facts,
    } = factList;

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "fun_fact_background_image_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

    const size = _.size(facts);
    const multipleOfThreeRemainder = size % 3;
    let updatedFactList = facts;

    if (size > 4 && multipleOfThreeRemainder !== 0) {
        const dropValue = size === 5 ? 1 : multipleOfThreeRemainder;

        updatedFactList = _.dropRight(factList.facts, dropValue);
    }

    const newSize = _.size(updatedFactList);

    const calculateItemStyle = (idx) => {
        const isEven = idx % 2 === 0;

        if (newSize === 1) {
            return {
                width: '100%',
            };
        }
        if (newSize === 2 || newSize === 4) {
            return {
                width: '50%',
                borderRight: isEven ? '20px solid white' : 'none',
            };
        }
        const newIdx = idx + 1;
        const isNotMultipleOfThree = newIdx % 3 !== 0;

        return {
            width: '33.33%',
            borderRight: isNotMultipleOfThree ? '20px solid white' : 'none',
        };
    };

    return (
        <>
            {
                navText
                    ? (
                        <h2 id={_.kebabCase(navText)}>
                            {heading}
                        </h2>
                    )
                    : (
                        <h2>
                            {heading}
                        </h2>
                    )
            }
            <h4>{description}</h4>

            <div
                className={list}
                data-testid="container"
            >
                {_.map(updatedFactList, (fact, idx) => {
                    return (
                        <div
                            key={fact}
                            className={itemContainer}
                            data-testid="item"
                            style={calculateItemStyle(idx)}
                        >
                            <Img fadeIn={false} fluid={data.placeholderImage.childImageSharp.fluid} style={{ height: 150 }} />
                            <h2
                                className={item}
                            >
                                {fact}
                            </h2>
                        </div>

                    );
                })}
            </div>
        </>
    );
};

FactList.propTypes = {
    factList: PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        navText: PropTypes.string,
        facts: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default FactList;
