import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import selectStyles from './select.module.scss';
import Chevron from '../../assets/chevron.svg';

const Select = ({
    placeholder, options, name, initialValue,
}) => {
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        _.get(initialValue, 'option', null) || {
            label: null,
            value: null,
        },
    );
    const {
        select,
        placeholderText,
        chevron,
        menuContainer,
        menuItem,
        selected,
        invalidWarning,
        invalid,
    } = selectStyles;

    const {
        register, errors, setValue, triggerValidation,
    } = useFormContext();

    useEffect(() => {
        document.addEventListener('mousedown', closeMenu, false);
        return () => {
            document.removeEventListener('mousedown', closeMenu, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        register(
            {
                name,
            },
            {
                validate: {
                    isValid: (value) => {
                        if (_.get(value, 'option.value') && !_.get(errors, `${name}`)) {
                            return true;
                        }
                        return false;
                    },
                },
            },
        );

        if (initialValue) {
            setValue(name, { ...initialValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeMenu = (e) => {
        if (e.defaultPrevented || _.includes(e.target.className, 'placeholder')
        || _.includes(e.target.className, 'menuItem')
        ) {
            return;
        }

        setSelectOpen(false);
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        setValue(name, { option });
        triggerValidation({ name });
        setSelectOpen(false);
    };

    return (
        <div
            className={`${select} ${_.get(errors, `${name}`) ? invalid : ''}`}
            data-testid="select"
        >
            <Chevron
                data-testid="chevron"
                className={chevron}
            />
            <div
                id={_.kebabCase(placeholder)}
                data-testid="placeholder"
                role="listbox"
                aria-expanded="false"
                tabIndex="0"
                onClick={() => {
                    setSelectOpen(!selectOpen);
                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                        setSelectOpen(!selectOpen);
                    }
                    if (e.keyCode === 27) {
                        setSelectOpen(false);
                    }
                }}
                className={`${placeholderText} placeholder`}
            >
                {selectedOption.value && !selectOpen ? selectedOption.label : placeholder}
            </div>
            {selectOpen ? (
                <div
                    data-testid="menu-container"
                    className={`${menuContainer} ${_.get(errors, `${name}`) ? invalid : ''}`}
                >
                    {_.map(options, (option) => {
                        return (
                            <div
                                className={`${menuItem} ${
                                    option.label === _.get(selectedOption, 'label') ? selected : ''
                                } menuItem`}
                                key={option.label}
                                data-testid="menu-item"
                                role="menuitem"
                                tabIndex="0"
                                onClick={() => {
                                    handleSelect(option);
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 32) {
                                        handleSelect(option);
                                    }
                                    if (e.keyCode === 27) {
                                        setSelectOpen(false);
                                    }
                                }}
                            >
                                {option.label}
                            </div>
                        );
                    })}
                </div>
            ) : null}

            {_.get(errors, `${name}`) ? (
                <p className={invalidWarning}>Please choose a service</p>
            ) : null}
        </div>
    );
};

Select.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        }),
    ).isRequired,
    name: PropTypes.string,
    initialValue: PropTypes.shape({
        option: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
    }),
};

Select.defaultProps = {
    placeholder: '',
    name: 'select',
    initialValue: null,
};

export default Select;
