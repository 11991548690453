import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import aboutWidgetStyles from './aboutWidget.module.scss';

const AboutWidget = () => {
    const { widgetContainer, widgetHeading } = aboutWidgetStyles;
    const data = useStaticQuery(graphql`
    query AboutWidgetQuery {
        graphCMS {
            aboutWidgets(last: 1)  {
                heading
                body
            }
        }
    }
`);

    const { aboutWidgets } = data.graphCMS;
    const { heading, body } = _.head(aboutWidgets);
    return (
        <div className={widgetContainer}>
            <h3 className={widgetHeading} data-testid="heading">{heading}</h3>
            <ReactMarkdown source={body} escapeHtml={false} />
        </div>
    );
};

export default AboutWidget;
