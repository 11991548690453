import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Link, useStaticQuery, graphql, navigate,
} from 'gatsby';
import Img from 'gatsby-image';
import HomePageBlogColumnStyles from './homePageBlogColumns.module.scss';
import {
    columnOneDefaults,
    columnTwoDefaults,
    columnThreeDefaults,
} from './homePageBlogColumns.constants';
import Arrow from '../../assets/arrow.svg';

const indexToColumnImageDict = {
    0: 'one',
    1: 'two',
    2: 'three',
};

const HomePageBlogColumns = ({ blogColumns }) => {
    const {
        blogColumnsContainer,
        innerContainer,
        columnContainer,
        subtext,
        linkItem,
        linkRow,
        noBorder,
    } = HomePageBlogColumnStyles;

    // first check that we have at least 3 blog columns from graphCMS, if so,
    // use the last 3, otherwise use defaults
    const columns = _.size(blogColumns) >= 3
        ? _.takeRight(blogColumns, 3)
        : [columnOneDefaults, columnTwoDefaults, columnThreeDefaults];

    const defaultImages = useStaticQuery(graphql`
        query {
            one: file(relativePath: { eq: "lawn-care-column.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 920, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            two: file(relativePath: { eq: "landscaping-column.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 920, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            three: file(relativePath: { eq: "grass-growing-column.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 920, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <div className={blogColumnsContainer}>
            <div className={innerContainer}>
                {_.map(columns, (column, idx) => {
                    return (
                        <div key={column.id} className={columnContainer} data-testid="blog-column">
                            <div
                                data-testid="header-links"
                                tabIndex="0"
                                role="link"
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}
                                onClick={() => {
                                    navigate(column.headerLink);
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        navigate(column.headerLink);
                                    }
                                }}
                                className={linkItem}
                            >
                                <Img
                                    fluid={
                                        _.get(column, 'image.localFile.childImageSharp.fluid')
                                        || defaultImages[indexToColumnImageDict[idx]].childImageSharp
                                            .fluid
                                    }
                                />
                                <span className={`${linkRow} ${noBorder}`}>
                                    <h2>{column.headerText}</h2>
                                    <Arrow />
                                </span>
                            </div>

                            <h4 className={subtext}>{column.headerSubText}</h4>
                            {_.map(column.homePageBlogLinks, (link) => {
                                return (
                                    <span
                                        data-testid="blog-links"
                                        tabIndex="0"
                                        role="link"
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        onClick={() => {
                                            navigate(link.path);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                navigate(link.path);
                                            }
                                        }}
                                        key={link.path}
                                        className={`${linkItem} ${linkRow}`}
                                    >
                                        <h4>{link.title}</h4>
                                        <Arrow />
                                    </span>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div>
                <Link to="/blog/">
                    <h3>Lastest Blog Articles</h3>
                    <Arrow />
                </Link>
            </div>
        </div>
    );
};

HomePageBlogColumns.propTypes = {
    blogColumns: PropTypes.arrayOf(
        PropTypes.shape({
            headerText: PropTypes.string,
            headerSubText: PropTypes.string,
            headerLink: PropTypes.string,
            homePageBlogLinks: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    path: PropTypes.string,
                }),
            ),
            image: PropTypes.shape({
                localFile: PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                        fluid: PropTypes.object,
                    }).isRequired,
                }).isRequired,
            }),
        }),
    ),
};

HomePageBlogColumns.defaultProps = {
    blogColumns: null,
};

export default HomePageBlogColumns;
