import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import serviceProjectsStyles from './serviceProjects.module.scss';
import ServiceProjectItem from './serviceProjectItem';

export function getNumEligibleServiceProjects(num, divisor) {
    const remainder = num % divisor;
    const eligible = num - remainder;
    return eligible < divisor ? 0 : eligible;
}

const ServiceProjects = ({ serviceProjects, cityName, stateSlug }) => {
    const {
        serviceProjectList,
        serviceProjectsSection,
    } = serviceProjectsStyles;

    const numEligibleForRendering = getNumEligibleServiceProjects(_.size(serviceProjects), 3);
    let serviceProjectsList = null;
    let eligibleServiceProjects = [];
    if (numEligibleForRendering) {
        eligibleServiceProjects = serviceProjects.slice(0, numEligibleForRendering);
        serviceProjectsList = _.map(eligibleServiceProjects, (serviceItem) => {
            return (
                <ServiceProjectItem key={serviceItem.id} serviceItem={serviceItem} cityName={cityName} stateSlug={stateSlug} />
            );
        });
    }

    return numEligibleForRendering ? (
        <div className={serviceProjectsSection}>
            <h2>
                Recent Projects by {cityName}, {_.toUpper(stateSlug)} Lawn Care Service Professionals
            </h2>
            <div className={serviceProjectList}>{serviceProjectsList}</div>
        </div>
    ) : null;
};

ServiceProjects.propTypes = {
    cityName: PropTypes.string.isRequired,
    stateSlug: PropTypes.string.isRequired,
    serviceProjects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            serviceName: PropTypes.string,
            jobDetails: PropTypes.string,
            price: PropTypes.number,
            yardSize: PropTypes.number,
            zip: PropTypes.string,
            requesterName: PropTypes.string,
            requestedDate: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
        }),
    ).isRequired,
};

export default ServiceProjects;
