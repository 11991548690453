import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DynamicTable from './components/dynamicTable';
import tableListStyles from './tableList.module.scss';

const TableList = ({ tableData }) => {
    const { container } = tableListStyles;

    return (
        <>
            {
                _.map(tableData, ({
                    heading, description, data, navText,
                }, idx) => {
                    return (
                        <div key={`${heading}${idx}`} className={container}>
                            <h2 data-testid="heading" id={navText ? _.kebabCase(navText) : ''}>{heading}</h2>
                            <h4 data-testid="description">{description}</h4>
                            <DynamicTable tableRows={data} />
                        </div>
                    );
                })
            }
        </>
    );
};

TableList.propTypes = {
    tableData: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})])),
        navText: PropTypes.string,
    })).isRequired,
};

export default TableList;
