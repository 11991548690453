
import React from 'react';
import _ from 'lodash';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import trendListStyles from './trendList.module.scss';

const TrendList = ({ trendList }) => {
    const { navText, heading, trends } = trendList;
    const trendListDescription = _.get(trendList, 'description');
    const {
        container, card, img, title, description,
    } = trendListStyles;

    return (
        <>
            {
                navText
                    ? (
                        <h2 id={_.kebabCase(navText)}>
                            {heading}
                        </h2>
                    )
                    : (
                        <h2>
                            {heading}
                        </h2>
                    )
            }
            { trendListDescription ? <p>{trendListDescription}</p> : null }
            <div className={container}>
                {_.map(trends, (trend, idx) => {
                    return (
                        <div key={`${trend.title}-${idx}`} className={card}>
                            <div>
                                <p className={title}>{trend.title}</p>
                                <p className={description}>{trend.description}</p>
                            </div>

                            {
                                trend.image ? (
                                    <div>
                                        <Img fadeIn={false} fluid={trend.image.localFile.childImageSharp.fluid} alt={trend.title} className={img} />
                                    </div>
                                ) : null
                            }
                        </div>
                    );
                })}
            </div>
            <p>* All photos on the Wikilawn website are free for commercial or non-commercial use. Visual attribution is required.</p>
        </>
    );
};

TrendList.propTypes = {
    trendList: PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        navText: PropTypes.string,
        image: PropTypes.string,
        trends: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                image: PropTypes.shape({
                    localFile: PropTypes.shape({
                        childImageSharp: PropTypes.shape({
                            fluid: PropTypes.object,
                        }),
                    }),
                }),
            }),
        ),
    }).isRequired,
};

export default TrendList;
