import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import postMetaStyles from './postMeta.module.scss';


const PostMeta = ({ node }) => {
    const { link, post } = postMetaStyles;
    const postCategories = _.map(node.categories, (tag, idx, categories) => {
        return (
            <Fragment key={tag.path}>
                <Link to={tag.path} className={link}>{_.toUpper(_.unescape(tag.name))}</Link>
                {idx < categories.length - 1 && ', '}
            </Fragment>
        );
    });

    return (
        <span className={post}>
        BY {_.toUpper(node.author.name)} | {_.toUpper(node.date)} | {postCategories}
        </span>
    );
};

PostMeta.propTypes = {
    node: PropTypes.shape({
        author: PropTypes.shape({
            name: PropTypes.string,
        }),
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string,
                path: PropTypes.string,
            }),
        ),
        title: PropTypes.string,
        date: PropTypes.string,
    }).isRequired,
};

export default PostMeta;
