import React from 'react';
import leadProcessExplainerStyles from './leadProcessExplaner.module.scss';
import DetailsSVG from '../../assets/details.svg';
import ProSVG from '../../assets/pro.svg';
import QuoteSVG from '../../assets/quote.svg';

const LeadProcessExplainer = () => {
    const { container, innerContainer } = leadProcessExplainerStyles;

    return (
        <div className={container}>
            <h2>A Dead Simple Way to Hire a Lawn Pro</h2>
            <div className={innerContainer}>
                <div>
                    <DetailsSVG />
                    <h3>1) Answer a few questions</h3>
                </div>
                <div>
                    <QuoteSVG />
                    <h3>2) Get quotes from up to 4 pros</h3>
                </div>
                <div>
                    <ProSVG />
                    <h3>3) Hire the best pro</h3>
                </div>
            </div>
        </div>
    );
};


export default LeadProcessExplainer;
