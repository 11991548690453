/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import authorProfileStyles from './authorProfile.module.scss';

const AuthorProfile = ({ author }) => {
    const { name, description, avatar_urls } = author;
    const { container, avatar, content } = authorProfileStyles;

    const backupImage = useStaticQuery(graphql`
        query {
            localFile: file(relativePath: { eq: "author-avatar.png" }) {
                childImageSharp {
                    fixed(height: 100, width: 100, quality: 75) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <div className={container}>
            <div className={avatar}>
                {avatar_urls.wordpress_96 ? (
                    <img src={avatar_urls.wordpress_96} alt="author avatar" data-testid="avatar" />
                ) : (
                    <Img
                        fadeIn={false}
                        fixed={backupImage.localFile.childImageSharp.fixed}
                        alt="avatar"
                    />
                )}
            </div>

            <div className={content}>
                <div>
                    <h4>Written by {name || 'Wikilawn'}</h4>
                    {author.description ? <p>{description}</p> : null}
                </div>
            </div>
        </div>
    );
};

AuthorProfile.propTypes = {
    author: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
        avatar_urls: PropTypes.shape({
            wordpress_96: PropTypes.string,
        }),
    }).isRequired,
};

export default AuthorProfile;
