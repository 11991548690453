import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import _ from 'lodash';
import ReactModal from 'react-modal';

const ChartSection = ({ chartSection }) => {
    ReactModal.setAppElement('#___gatsby');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        heading, description, image, navText,
    } = chartSection;

    const fluid = _.get(image, 'localFile.childImageSharp.fluid');

    return (
        <>
            {
                navText
                    ? (
                        <h2 id={_.kebabCase(navText)}>
                            {heading}
                        </h2>
                    )
                    : (
                        <h2>
                            {heading}
                        </h2>
                    )
            }
            <p>
                {description}
            </p>
            <div
                data-testid="clickable"
                style={{ cursor: 'pointer', outline: 'none' }}
                tabIndex={0}
                role="button"
                onKeyPress={(e) => {
                    e.preventDefault();
                    return e.keyCode === 13 ? setIsModalOpen(true) : null;
                }}
                onClick={(e) => {
                    e.preventDefault();
                    return setIsModalOpen(true);
                }}
            >
                <Img fadeIn={false} fluid={fluid} alt={heading} data-testid="image" />
            </div>

            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => { return setIsModalOpen(false); }}
                className="chartSectionModal"
                overlayClassName="chartSectionOverlay"
            >
                <Img fluid={fluid} alt={heading} data-testid="image" />
            </ReactModal>
        </>
    );
};

ChartSection.propTypes = {
    chartSection: PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        navText: PropTypes.string,
        image: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fluid: PropTypes.object,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default ChartSection;
