/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _ from 'lodash';
import breadcrumbsStyles from './breadcrumbs.module.scss';

const Breadcrumbs = ({
    service, state, city,
}) => {
    const {
        container, carat, link, active,
    } = breadcrumbsStyles;
    // need to check for a state for the Washington, DC case since it doesn't have one
    const stateSlug = _.get(state, 'slug');

    return (
        <ol className={container} data-testid="container" vocab="https://schema.org/" typeof="BreadcrumbList">
            <li property="itemListElement" typeof="ListItem">
                <Link to="/" className={link} activeClassName={active} property="item" typeof="WebPage">
                    <span property="name">Home</span>
                </Link>
                <meta property="position" content="1" />

            </li>
            <>
                <span className={carat}>»</span>
                <li property="itemListElement" typeof="ListItem">
                    <Link to={`/${service.slug}/`} className={link} activeClassName={active} property="item" typeof="WebPage">
                        <span property="name">{service.name}</span>
                    </Link>
                    <meta property="position" content="2" />
                </li>
            </>
            {state
                ? <>
                    <span className={carat}>»</span>
                    <li property="itemListElement" typeof="ListItem">
                        <Link to={`/${service.slug}/${state.slug}/`} className={link} activeClassName={active} property="item" typeof="WebPage">
                            <span property="name">{state.name} {service.name}</span>
                        </Link>
                        <meta property="position" content="3" />
                    </li>
                </> : null }
            {city
                ? <>
                    <span className={carat}>»</span>
                    <li property="itemListElement" typeof="ListItem">
                        <Link to={`/${service.slug}${stateSlug ? `/${stateSlug}` : ''}/${city.slug}/`} className={link} activeClassName={active} property="item" typeof="WebPage">
                            <span property="name">
                                {city.name}{stateSlug ? `, ${_.toUpper(state.slug)}` : ''} {service.name}
                            </span>
                        </Link>
                        <meta property="position" content={state ? '4' : '3'} />
                    </li>
                </> : null }
        </ol>
    );
};

Breadcrumbs.propTypes = {
    service: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
    }).isRequired,
    state: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
    }),
    city: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
    }),
};

Breadcrumbs.defaultProps = {
    state: null,
    city: null,
};

export default Breadcrumbs;
