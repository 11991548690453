export const columnOneDefaults = {
    id: 1,
    headerText: 'Lawn Care',
    headerSubText: 'Yes, lawn care starts with trimming the top off a raggedy lawn. But you also need to aerate, fertilize and control weeds.',
    headerLink: '/lawn-care/',
    homePageBlogLinks: [
        { title: 'Aerating your lawn', path: '/lawn-care/lawn-aeration-guide/' },
        { title: 'How to Edge Your Lawn', path: '/lawn-care/how-to-edge-a-lawn/' },
        { title: 'The Best Push Lawn Mowers', path: '/lawn-care/best-push-lawn-mowers/' },
        { title: 'Understanding Your Soil Types', path: '/gardening-tips/guide-understanding-soil-types/' },
    ],
};

export const columnTwoDefaults = {
    id: 2,
    headerText: 'Landscaping',
    headerSubText: 'Plopping down pretty plants in your yard is nice, but a great landscape requires more effort. Your landscape “wow” factor awaits.',
    headerLink: '/category/blog/landscaping/',
    homePageBlogLinks: [
        { title: 'Creating a Backyard Zen Garden', path: '/lawn-care/how-to-create-a-backyard-japanese-zen-garden/' },
        { title: '50 Popular Plants and Their Names', path: '/reference/50-popular-plants-and-their-common-names/' },
        { title: '20 Modern Pergola Designs for Your Landscape', path: '/garden-design/20-modern-pergola-designs-for-your-landscape/' },
        { title: '10 Plants that Repel Nasty Insects', path: '/home-garden/plants-that-repel-insects/' },
    ],
};

export const columnThreeDefaults = {
    id: 3,
    headerText: 'Growing & Caring for Grass',
    headerSubText: 'Your local climate, the soil, the sun and landscape all influence the type of grass you select.',
    headerLink: '/lawn-care/how-to-grow-grass/',
    homePageBlogLinks: [
        { title: 'Zoysia Grass', path: '/lawn-care/everything-you-need-to-know-about-zoysia-grass/' },
        { title: 'Bermuda Grass', path: '/lawn-care/lawn-care-the-definitive-guide-to-bermuda-grass/' },
        { title: 'Kentucky Bluegrass', path: '/blog/landscaping/everything-to-know-about-kentucky-bluegrass/' },
        { title: 'St Augustine Grass', path: '/lawn-care/the-definitive-guide-st-augustine-grass' },
    ],
};
