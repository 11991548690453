import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _ from 'lodash';
import nearbyLocationsStyles from './nearbyLocations.module.scss';

const NearbyLocations = ({ city, state, isPost }) => {
    const {
        leftPadding,
        locations,
        location,
        fullWidth,
    } = nearbyLocationsStyles;

    const getLinks = () => {
        // city with suburbs
        if (_.get(city, 'suburbs') && _.size(city.suburbs)) {
            return city.suburbs;
        }
        // suburbs
        if (_.get(city, 'parentCity')) {
            return _.concat(city.parentCity, _.reject(city.parentCity.suburbs, (suburb) => { return suburb.slug === city.slug; }));
        }
        // state
        if (!city) {
            return _.reject(state.cities, (stateCity) => {
                return !_.size(stateCity.locationPages);
            });
        }

        // city without suburbs and no parentCity
        return [];
    };

    if (!_.size(getLinks())) {
        return null;
    }

    const sortedLinks = _.sortBy(getLinks(), [(link) => { return link.name; }]);

    return (
        <div data-testid="nearby-locations" className={isPost ? leftPadding : ''}>
            <h3>Nearby Locations</h3>
            <ul className={locations}>
                {
                    _.map(sortedLinks, (link) => {
                        return (
                            <li
                                className={`${location} ${isPost ? fullWidth : ''}`}
                                key={link.name}
                            >
                                <Link to={`/lawn-care/${state.slug}/${link.slug}/`} data-testid="link">
                                    {`${link.name}, ${_.toUpper(state.slug)}`}
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

NearbyLocations.propTypes = {
    city: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        state: PropTypes.shape({}),
        parentCity: PropTypes.shape({
            name: PropTypes.string,
            slug: PropTypes.string,
            suburbs: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        suburbs: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }),
    state: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        cities: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    isPost: PropTypes.bool,
};

NearbyLocations.defaultProps = {
    city: null,
    isPost: false,
};

export default NearbyLocations;
