import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import paginationStyles from './pagination.module.scss';

const Pagination = ({ nextPageLink, prevPageLink }) => {
    return (
        <div className={paginationStyles.pageLinks}>
            <span data-testid="prevPageLink">
                {prevPageLink ? <Link to={prevPageLink}>« Previous Page</Link> : null}
            </span>
            <span data-testid="nextPageLink">
                {nextPageLink ? <Link to={nextPageLink}>Next Page »</Link> : null}
            </span>
        </div>
    );
};

Pagination.defaultProps = {
    prevPageLink: null,
    nextPageLink: null,
};

Pagination.propTypes = {
    prevPageLink: PropTypes.string,
    nextPageLink: PropTypes.string,
};

export default Pagination;
