/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import PostMeta from '../postMeta/postMeta';
import PostSummaryStyles from './postSummary.module.scss';

const PostSummary = ({ node }) => {
    const fluid = _.get(node, 'featured_media.localFile.childImageSharp.fluid');
    const {
        post, image, link, postTitle,
    } = PostSummaryStyles;

    return (
        <div className={post} data-testid="post">
            <Link to={node.path} className={link}>
                {fluid ? (
                    <Img fadeIn={false} fluid={fluid} className={image} />
                ) : null}
                <div data-testid="postTitle" className={postTitle} dangerouslySetInnerHTML={{ __html: node.title }} />
            </Link>
            <PostMeta node={node} />
            <div
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
            />
        </div>
    );
};

PostSummary.propTypes = {
    node: PropTypes.shape({
        author: PropTypes.shape({
            name: PropTypes.string,
        }),
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string,
                path: PropTypes.string,
            }),
        ),
        path: PropTypes.string,
        excerpt: PropTypes.string,
        title: PropTypes.string,
        date: PropTypes.string,
        featured_media: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fluid: PropTypes.object,
                }),
            }),
        }),
    }).isRequired,
};

export default PostSummary;
