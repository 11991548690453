/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import _ from 'lodash';
import featuredCompanyListStyles from './featuredCompanyList.module.scss';
import Star from '../../assets/star.svg';
import JumpLinkButton from '../jumpLinkButton/jumpLinkButton';
import CtaBanner from '../ctaBanner/ctaBanner';

const FeaturedCompanyList = ({ featuredCompanySection, companies }) => {
    const {
        companyList,
        companyContainer,
        noBottomBorder,
        img,
        blurb,
        companyNameRow,
        companyNameText,
        address,
        reviewRow,
        companyBio,
        buttonMobile,
        buttonDesktop,
        averageRatingText,
        reviewCountText,
    } = featuredCompanyListStyles;
    // eslint-disable-next-line object-curly-newline
    const { heading, description, maxNumberDisplayed, navText } = featuredCompanySection;
    const companyListSize = _.size(companies);
    const featuredCompanies =
        companyListSize > maxNumberDisplayed
            ? _.dropRight(companies, companyListSize - maxNumberDisplayed)
            : companies;

    // Simple regex to work with our mustache template syntax
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const templaterHeading = _.template(heading);
    const templaterNavText = _.template(navText);
    const templaterDescription = _.template(description);
    const companyCountDisplay = companyListSize >= 5 ? _.size(featuredCompanies) : '';
    const featuredCompanyCount = _.size(featuredCompanies);
    const featuredCompanyCountIsEven = featuredCompanyCount % 2 === 0;
    const ctaIndexPosition = featuredCompanyCountIsEven
        ? featuredCompanyCount / 2 - 1
        : featuredCompanyCount / 2 - 0.5;


    return (
        <div>
            {navText ? (
                <h2
                    className={companyList}
                    id={_.kebabCase(templaterNavText({ count: companyCountDisplay }))}
                    data-testid="nav-text"
                >
                    {templaterHeading({ count: companyCountDisplay })}
                </h2>
            ) : (
                <h2 className={companyList} data-testid="heading">
                    {heading}
                </h2>
            )}

            {description ? (
                <p className={companyList}>
                    {templaterDescription({ count: companyCountDisplay })}
                </p>
            ) : null}

            {_.map(featuredCompanies, (company, idx) => {
                const {
                    companyName,
                    ownerName,
                    companyLogo,
                    addressDisplay,
                    bio,
                    averageReviewRating,
                    reviewCount,
                } = company;
                const hasCompanyLogo = Boolean(
                    _.get(companyLogo, 'localFile.childImageSharp.fluid'),
                );

                const showCta = ctaIndexPosition === idx;

                return (
                    <div key={companyName}>
                        <div
                            className={`${companyContainer} ${showCta ? noBottomBorder : ''}`}
                        >
                            {hasCompanyLogo ? (
                                <div className={img}>
                                    <Img
                                        fadeIn={false}
                                        fluid={companyLogo.localFile.childImageSharp.fluid}
                                        alt={companyName}
                                    />
                                </div>
                            ) : null}
                            <div className={blurb}>
                                <div className={companyNameRow}>
                                    <h3 className={companyNameText}>
                                        {idx + 1}) {companyName} - {ownerName}
                                    </h3>
                                    <div className={buttonDesktop}>
                                        <JumpLinkButton />
                                    </div>
                                </div>
                                <div>
                                    <address className={address}>
                                        {_.toUpper(addressDisplay)}
                                    </address>
                                </div>
                                <p className={companyBio}>{bio}</p>
                                {averageReviewRating && reviewCount && (
                                    <p className={reviewRow}>
                                        <span className={averageRatingText}>
                                            {averageReviewRating}{' '}
                                        </span>
                                        <Star />
                                        <span className={reviewCountText}>({reviewCount})</span>
                                    </p>
                                )}
                            </div>
                            <div className={buttonMobile}>
                                <JumpLinkButton />
                            </div>
                        </div>
                        {showCta ? (
                            <CtaBanner
                                heading="Get FREE Quotes from up to 4 Professionals"
                                subHeading="Enter details of your project to get no-obligation quotes from pros."
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

FeaturedCompanyList.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            companyName: PropTypes.string,
            companyLogo: PropTypes.shape({
                localFile: PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                        fluid: PropTypes.object,
                    }).isRequired,
                }).isRequired,
            }),
            ownerName: PropTypes.string,
            bio: PropTypes.string,
            addressDisplay: PropTypes.string,
            website: PropTypes.string,
            averageReviewRating: PropTypes.number,
            reviewCount: PropTypes.number,
        }),
    ).isRequired,
    featuredCompanySection: PropTypes.shape({
        heading: PropTypes.string,
        navText: PropTypes.string,
        description: PropTypes.string,
        maxNumberDisplayed: PropTypes.number,
    }).isRequired,
};

export default FeaturedCompanyList;
