/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import reviewsModalStyles from './reviewsModal.module.scss';
import ReviewStars from '../reviewStars/reviewStars';

const ReviewsModal = ({ reviews, cityName, onCloseModal }) => {
    const {
        reviewsModalContainer, reviewSection, service, excerpt, date, navigationRow, paginationButton, closeButton, buttonRow,
    } = reviewsModalStyles;
    const chunkedReviews = _.chunk(reviews, 4);
    const pageCount = _.size(chunkedReviews);
    const [currentPage, setCurrentPage] = useState(1);
    const selectedReviews = chunkedReviews[currentPage - 1];

    return (
        <div className={reviewsModalContainer}>
            <h2>
                Reviews for Lawn Care Service in {cityName}
            </h2>
            {_.map(selectedReviews, (review) => {
                // need to check for a state for the Washington, DC case since it doesn't have one
                const stateName = _.get(review.city, 'state.name');

                return (
                    <div className={reviewSection} key={review.id}>
                        <div>
                            <div className={service} data-testid="stars">
                                <ReviewStars rating={review.rating} />
                            </div>
                            <div className={service}>{review.serviceName} by {review.providerName}</div>
                            <div className={service}>{review.companyName}</div>
                            <div className={excerpt}><p>{review.text}</p></div>
                        </div>
                        <div>
                            <p className={date} data-testid="location">{_.toUpper(review.city.name)}{stateName ? `, ${_.toUpper(review.city.state.name)}` : ''} | {_.toUpper(dayjs(review.date).format('MMM DD, YYYY'))}</p>
                        </div>
                    </div>
                );
            })}
            <div className={navigationRow}>
                <span>
                Page {currentPage} of {pageCount}
                </span>
                <span className={buttonRow}>
                    <button
                        className={closeButton}
                        onClick={onCloseModal}
                        type="button"
                    >X</button>
                    {chunkedReviews[currentPage - 2]
                        ? <button
                            className={paginationButton}
                            onClick={(e) => {
                                e.preventDefault();
                                return setCurrentPage(currentPage - 1);
                            }}
                            type="button"
                        >
                            {'<'}
                        </button> : null}
                    {chunkedReviews[currentPage]
                        ? <button
                            className={paginationButton}
                            onClick={(e) => {
                                e.preventDefault();
                                return setCurrentPage(currentPage + 1);
                            }}
                            type="button"
                        >
                            {'>'}
                        </button> : null}
                </span>
            </div>
        </div>
    );
};

ReviewsModal.propTypes = {
    reviews: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            rating: PropTypes.number,
            text: PropTypes.string,
            date: PropTypes.string,
            companyName: PropTypes.string,
            providerName: PropTypes.string,
            serviceName: PropTypes.string,
            city: PropTypes.shape({
                name: PropTypes.string,
                state: PropTypes.shape({
                    name: PropTypes.string,
                }),
            }),
        }).isRequired,
    ).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    cityName: PropTypes.string.isRequired,
};

export default ReviewsModal;
