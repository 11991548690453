import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import HorizontalList from '../horizontalList/horizontalList';
import ResourceCard from '../resourceCard/resourceCard';

const MoreResources = ({ posts }) => {
    const componentArray = _.map(posts, (post) => {
        const { node } = post;
        return (
            <ResourceCard node={node} key={node.id} />
        );
    });

    return (
        <div>
            <h2>
                More Resources
            </h2>
            <HorizontalList componentArray={componentArray} />
        </div>
    );
};

MoreResources.propTypes = {
    posts: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                id: PropTypes.string,
                path: PropTypes.string,
                date: PropTypes.string,
                title: PropTypes.string,
                excerpt: PropTypes.string,
                featured_media: PropTypes.shape({
                    localFile: PropTypes.object,
                }),
            }),
        }),
    ).isRequired,
};

export default MoreResources;
