import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import reviewStarsStyles from './reviewStars.module.scss';

const ReviewStars = ({
    rating,
}) => {
    const {
        star,
    } = reviewStarsStyles;

    return (
        <div data-testid="review-stars-container">
            {_.times(rating, (idx) => {
                return <i role="presentation" aria-label="star" className={star} key={idx} />;
            })}
        </div>
    );
};

ReviewStars.propTypes = {
    rating: PropTypes.number.isRequired,
};

export default ReviewStars;
